/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Self = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [localArea, setLocalArea] = useState(0);
    const [bigHeader, setBigHeader] = useState('no');
    const [maxImagesAllowed, setMaxImagesAllowed] = useState(2);
    const [category, setCategory] = useState(3);
    const [hasImages, setHasImages] = useState(true);
    const [images, setImages] = useState([]);
    const [leftMenuSpace, setLeftMenuSpace] = useState('');
    const [icons, setIcons] = useState([]);
    const [videos, setVideos] = useState([]);
    const [webColor, setWebColor] = useState('');
    const [bigHeaderOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);
    const [topBarOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);

    const [belowBarOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);

    const [menuLeftSpaceOptions] = useState([{ name: 'None', value: '' }, { name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' }]);

    const [sliderWidthOptions] = useState([{ name: 'Normal', value: 'normal' }, { name: 'Small', value: 'small' }, { name: 'X-Small', value: 'x-small' }]);


    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [iconUploadButtonDisabled, setIconUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    const [status, setStatus] = useState('');

    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        console.log('params', props.match);
        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
    }

    const deleteImage = (id) => {
        const newImages = images.filter(image => image.id !== id);
        setImages(newImages);
        coreContext.deleteImage(id);
    }

    const populateClient = () => {
        console.log(coreContext.client);
        setValue('name', coreContext.client.item_name);
        setValue('contactName', coreContext.client.item_contact_person);
        setValue('tag', coreContext.client.item_tag);
        setValue('loc', coreContext.client.item_loc);
        setValue('email', coreContext.client.item_email);
        setValue('customcss', coreContext.client.item_custom_css);
        setValue('contact1', coreContext.client.item_mobile_1);
        setValue('contact2', coreContext.client.item_mobile_2);
        setValue('contact', coreContext.client.item_contact);
        setValue('address', coreContext.client.item_address);
        setValue('description', coreContext.client.item_description);
        setValue('pincode', coreContext.client.item_pincode);
        // setLocalArea(coreContext.client.areaId);
        setValue('state', coreContext.client.stateId + '');
        setValue('district', coreContext.client.districtId);
        setValue('country', coreContext.client.countryId);
        setImages(coreContext.client.images);
        setVideos(coreContext.client.video_links);
        setStatus(coreContext.client.item_status);
        setValue('big_header', coreContext.client.show_big_header);
        setValue('left_menu_slug', coreContext.client.menu_left_slug);
        setValue('menu_left_space', coreContext.client.menu_side_space_left);

        setValue('otime', coreContext.client.otime);
        setValue('twitter', coreContext.client.twitter);
        setValue('facebook', coreContext.client.facebook);
        setValue('google', coreContext.client.google);
        setValue('youtube', coreContext.client.youtube);
        setValue('metakeyword', coreContext.client.site_meta_keyword);
        setValue('applyurl', coreContext.client.apply_url);
        setValue('socialbtnurl', coreContext.client.social_btn_url);
        setValue('applybtntext', coreContext.client.apply_btn_text);
        setValue('socialbtntext', coreContext.client.social_btn_text);
        setValue('webcolor', coreContext.client.theme_color);
        setValue('slider_height', coreContext.client.slider_height);
        setValue('slider_width', coreContext.client.slider_width);
        setValue('slider_side_padding', coreContext.client.slider_side_padding);
        setValue('top_bar', coreContext.client.show_top_bar);
        setValue('below_bar', coreContext.client.show_below_bar);
        setValue('menu_font_size', coreContext.client.menu_font_size);
        setValue('left_header_slug', coreContext.client.logo_side_space_left);
        setValue('right_header_slug', coreContext.client.logo_side_space_right);
        setValue('google_analytics', coreContext.client.google_analytics);
    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (images) {
            const logos = images.filter(image => image.itype === 'logo');

            if (logos)
                return logos.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>

                </div>)
        }
    }

    const renderIcons = () => {
        if (images) {
            const icons = images.filter(image => image.itype === 'icon');
            if (icons)
                return icons.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button>

                </div>)
        }
    }


    const { register, handleSubmit, formState: { errors }, setValue } = useForm({}
    );


    const clientEditHandler = (data) => {
        // console.log('here also');

        const { name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, state, district, country, otime, twitter, facebook, google, youtube, metakeyword, applyurl, socialbtnurl, applybtntext, socialbtntext, webcolor, big_header, slider_height, menu_font_size, left_header_slug, right_header_slug, google_analytics, slider_width, slider_side_padding, top_bar, below_bar, left_menu_slug, menu_left_space, customcss } = data;



        coreContext.editSelf(authContext.itemId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, state, district, category, country, status, otime, twitter, facebook, google, youtube, metakeyword, applyurl, webcolor, big_header, slider_height, menu_font_size, left_header_slug, right_header_slug, google_analytics, slider_width, slider_side_padding, top_bar, below_bar, left_menu_slug, menu_left_space, customcss, socialbtnurl, applybtntext, socialbtntext);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const onIconsSelected = (icons) => {
        setSelectedImages(icons);
        //  console.log(icons);
        if (icons.length > 0) setIconUploadButtonDisabled(false); else setIconUploadButtonDisabled(true);
    }

    const renderImageUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='4000' pictures={selectedImages} />
        else return '';
    }

    const renderIconUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onIconsSelected} maxWidthOrHeight='400' pictures={selectedImages} />
        else return '';
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        // if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            formData.append('itype', 'logo');
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    //   navigate('/');
                }, 5000);
            };
        }



    }

    const onIconUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        // if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            formData.append('itype', 'icon');
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    // navigate('/');
                }, 5000);
            };
        }



    }

    const onApplyDefaultColorChangedHandler = () => {
        setValue('webcolor', '#00B16A');
        applyThemeColor('#00B16A', coreContext.client.id);
    }

    const onApplyColorChangedHandler = (color) => {
        setValue('webcolor', color.hex);
        applyThemeColor(color.hex, coreContext.client.id)
    }

    const onApplyColorChangedHandler1 = (e) => {
        //  console.log('color', e.target.value);
        setWebColor(e.target.value);
        applyThemeColor(e.target.value, coreContext.client.id)
    }

    const applyThemeColor = (color, itemId) => {
        coreContext.applyThemeColor(color, itemId);
    }
    const onBigHeaderChangedHandler = (e) => {
        setBigHeader(e.target.value);
    }

    const onMenuLefSpaceChangedHandler = (e) => {
        setLeftMenuSpace(e.target.value);
    }

    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Kindly Complete Your Profile..</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' />

                            <Input label='Title' name='tag' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Site Title . eg: "Siddhanta Technnology Services || The best School ERP provider || best Mobile App provider" ...Google use this title for SEO' />


                            <Input label='Meta Description' name='description' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description ... eg. "We are the best school in Muzaffarpur."  ...Google use these descriptions for SEO.' />

                            <Input label='Meta Key words' name='metakeyword' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta key words ... eg. "best school, school, Muzaffarpur etc."  ...Google use these key words for SEO.' />

                            <Input label='Opening Time' name='otime' minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter School Opeining Time eg. 9:30 am to 1 pm' />

                            <Input label='Slider Height' name='slider_height' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter slider height eg. 500px' />

                            <Input label='Slider Width' name='slider_width' required={true} register={register} errors={errors} elementType='select' options={sliderWidthOptions} />

                            <Input label='Slider Side Padding' name='slider_side_padding' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter slider side padding eg. 55px' />

                            <Input label='Menu Left Space' name='menu_left_space'
                                register={register} required={false} errors={errors} elementType='select' options={menuLeftSpaceOptions} onChange={onMenuLefSpaceChangedHandler} />

                            {leftMenuSpace ? <Input label='Menu Left Slug' name='left_menu_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste left menu slug' />
                                : null}

                            <Input label='Menu Font Size' name='menu_font_size' maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter menu font size eg. 20px' />

                            <Input label='Big Header' name='big_header' required={true} register={register} errors={errors} elementType='select' options={bigHeaderOptions} onChange={onBigHeaderChangedHandler} />


                            {bigHeader === 'yes' ? <Input label='Header Left Slug' name='left_header_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste left header slug' />
                                : null}

                            {bigHeader === 'yes' ? <Input label='Header Right Slug' name='right_header_slug' maxLength={100} required={false} register={register} errors={errors} elementType='text' placeholder='Copy/ paste right header slug' />
                                : null}

                            <Input label='Show Top Bar' name='top_bar' required={true} register={register} errors={errors} elementType='select' options={topBarOptions} />

                            <Input label='Show Below Bar' name='below_bar' required={true} register={register} errors={errors} elementType='select' options={belowBarOptions} />

                            <Input label='Twitter Link' name='twitter' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Twitter Link' />

                            <Input label='Facebook Link' name='facebook' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Facebook Link' />

                            <Input label='Google+ Link' name='google' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Google+ Link' />

                            <Input label='Youtube Link' name='youtube' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Youtube Chhanel Link' />

                            <Input label='Apply Button Text' name='applybtntext' minLength={2} maxLength={50} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Apply Now Button Text' />

                            <Input label='Apply Button URL' name='applyurl' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Apply Now Button Url' />

                            <Input label='Social Button Text' name='socialbtntext' minLength={2} maxLength={50} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Social Button Text' />

                            <Input label='Social Button URL' name='socialbtnurl' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Social Button Url' />

                            <Input label='Apply Website Color' name='webcolor' minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='color' placeholder='Choose Theme Color' onChange={onApplyColorChangedHandler1} />

                            <div className="row"><div className="col-md-9">
                                <Form.Label><h6><Badge variant='dark'>Choose Website Theme Color</Badge></h6></Form.Label>
                                <CirclePicker
                                    color={webColor}
                                    onChangeComplete={onApplyColorChangedHandler}
                                />
                            </div>
                                <div className="col-md-3"> <Input elementType='checkbox' value="Apply Default Theme Color" onChange={onApplyDefaultColorChangedHandler} /></div>
                            </div>
                            <br />

                            <Input label='Custom CSS' name='customcss' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Custom Css' />

                            <Input label='LOC' name='loc' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Map Embed Code  from map.google.com' />

                            <Input label='Google Analytics Code' name='google_analytics' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Analytics Code' />

                            <Input label='Contact Person Name' name='contactName' minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Contact Person Name' />

                            <Input label='Contact' name='contact' minLength={10} maxLength={20} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No.' />

                            <Input label='Mobile-I' name='contact1' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' />

                            <Input label='Mobile-II' name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' />

                            <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' />

                            {/* <Input label='Reset Password' required={false} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Reset Password' onChange={onPasswordChangedHandler} /> */}

                            <Input label='Address' name='address' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Complete Address to be shown on Website' />

                            <Input label='Pincode' name='pincode' minLength={6} maxLength={6} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Pincode' />

                            <Input label='Country' name='country' required={true} register={register} errors={errors} elementType='select' options={coreContext.countryOptions} />

                            <Input label='State' name='state' required={true} register={register} errors={errors} elementType='select' options={coreContext.stateOptions} />

                            <Input label='District' name='district' required={true} register={register} errors={errors} elementType='select' options={coreContext.districtOptions} />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ maxHeight: '600px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">

                    <div className="card-body">
                        {renderImageUploader()}
                        <Input value='Upload Site Logo' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>

                </div>
                <div className="card">
                    <div className="card-header">Logo</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        {renderIconUploader()}
                        <Input value='Upload Site Icon (Title Bar Icon)' onClick={onIconUploadHandler} disabled={iconUploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card">
                        <div className="card-header">Site Icon</div>
                        <div className="card-body">
                            <div className="row">
                                {renderIcons()}
                            </div>
                        </div>
                    </div> <br /><br />
                </div>
            </div>
        </div> </div>);
}


export { Self };